import React from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col, Button } from 'react-bootstrap';
import CreateUser from './components/create-tenant';
import UpdateTenantPage from './components/update-tenant';
import LogoIcon from './assets/images/plum_asset_logo.svg';

export const Routes: React.FC<{ onSignOut(): void }> = ({ onSignOut }) => {
  return (
    <Router>
      <Navbar>
        <Navbar.Brand>
          <LogoIcon />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="/tenant/create" className={'px-2'}>
              Create Tenant
            </NavLink>
            <NavLink to="/tenant/update" className={'px-2'}>
              Update Tenant
            </NavLink>
          </Nav>
          <Nav>
            <Nav.Link eventKey={1} as="div">
              <Button onClick={onSignOut}>Sign Out</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        <Row>
          <Col md={12}>
            <Switch>
              <Route exact path="/" component={CreateUser as any} />
              <Route path="/tenant/create" component={CreateUser as any} />
              <Route path="/tenant/update" component={UpdateTenantPage as any} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </Router>
  );
};
